import requestQuoteIcon from "assets/images/requestQuoteLight.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { SalesInvoices } from "routes/SalesInvoices";
import { FilterList } from "../../../components/filtersSection/filterList/FilterList";
import { countryCodes, currenciesToPick } from "CONSTANTS";
import { FilterType } from "components/common/filters/types";
import { getRoutesSimplifiedItems } from "api/routes/calls";
import { getSimplifiedOrderGroups } from "api/order-groups/calls";
import { tradingDocumentConstants } from "constants/tradingDocuments";

export const salesInvoices: ModuleLink = {
  url: "sales",
  label: "Faktury Sprzedażowe",
  icon: requestQuoteIcon,
  subSection: (
    <FilterList
      filters={[
        {
          type: FilterType.DateRange,
          label: "data sprzedaży",
          name: ["soldAfter", "soldBefore"],
          showMonths: true,
        },
        {
          type: FilterType.DateRange,
          label: "data wystawienia",
          name: ["fromInvoiceIssueDate", "toInvoiceIssueDate"],
          showMonths: true,
        },
        {
          type: FilterType.Select,
          label: "państwo",
          name: "countryCode",
          multiple: true,
          kind: "label",
          options: countryCodes.map(({ icon, value }) => ({
            label: value,
            value,
            icon,
          })),
        },
        {
          type: FilterType.Search,
          label: "konto sprzedażowe",
          name: "salesAccount",
          multiple: true,
          searchBy: "salesAccounts",
        },
        {
          type: FilterType.AsyncSearch,
          fetchFrom: getRoutesSimplifiedItems,
          label: "Numer trasy",
          name: "routes",
          value: "id",
          display: "signature",
          placeholder: "Szukaj trasy",
        },
        {
          type: FilterType.AsyncSearch,
          fetchFrom: getSimplifiedOrderGroups,
          label: "Numer grupy zamówień",
          name: "orderGroups",
          value: "id",
          display: "signature",
          placeholder: "Szukaj grupy",
        },
        {
          type: FilterType.Search,
          label: "sprzedawcy",
          name: "sellers",
          multiple: true,
          searchBy: "businessEntities",
          overrides: {
            businessEntitiesKind: "INTERNAL",
          },
        },
        {
          type: FilterType.Select,
          label: "Czy odbiorca faktury ma NIP?",
          name: "hasBuyerTaxId",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "Czy jest sfiskalizowane?",
          name: "isFiscalized",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "Czy potrzebny paragon?",
          name: "canBeFiscalized",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "status faktury",
          name: "status",
          options: [
            { label: "Zatwierdzone", value: "CONFIRMED" },
            { label: "Niezatwierdzone", value: "NEW" },
          ],
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "typ klienta",
          multiple: true,
          name: "kind",
          options: tradingDocumentConstants.tradingDocumentKindOptions,
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "status drukowania",
          multiple: true,
          name: "correspondingReceiptPrintingStatus",
          options: [
            { label: "niewydrukowane", value: "NOT_STARTED" },
            { label: "w trakcie fiskalizacji", value: "IN_PROGRESS" },
            { label: "wydrukowane", value: "FINISHED" },
            { label: "niepowodzenie", value: "FAILED" },
          ],
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "procedura podatkowa",
          name: "taxProcedure",
          options: tradingDocumentConstants.taxProcedureFilterOptions,
        },
        {
          type: FilterType.Select,
          label: "status płatności",
          name: "isPaid",
          options: [
            { label: "Opłacono", value: "true" },
            { label: "Nie opłacono", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "waluta",
          name: "currency",
          kind: "label",
          options: currenciesToPick,
          multiple: true,
        },
        {
          type: FilterType.Text,
          label: "nr zlecenia kurierskiego",
          placeholder: "Szukaj nr zlecenia kurierskiego",
          name: "pickupId",
        },
        {
          type: FilterType.Text,
          label: "nr przesyłki",
          placeholder: "Szukaj nr przesyłki",
          name: "trackingNumber",
        },
        {
          type: FilterType.Select,
          label: "sposób płatności",
          name: "paymentType",
          options: tradingDocumentConstants.paymentMethodsOptions,
        },
        {
          type: FilterType.Select,
          kind: "label",
          label: "forma płatności",
          multiple: true,
          name: "paymentProvider",
          options: tradingDocumentConstants.paymentProviderOptions,
        },
        {
          type: FilterType.SelectUser,
          label: "kto się zajmuje?",
          multiple: true,
          name: "isAssigned",
          kind: "user",
          searchBy: "standardUsers",
        },
        {
          type: FilterType.Search,
          label: "kontrahent",
          name: "customers",
          multiple: true,
          searchBy: "customers",
        },
        {
          type: FilterType.Date,
          label: "data przelewu",
          name: "transferDate",
        },
        {
          type: FilterType.Select,
          label: "czy pobrano XML?",
          name: "isXmlOptimaDownloaded",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Select,
          label: "zaległe faktury",
          name: "isPaymentOverdue",
          options: [
            { label: "Tak", value: "true" },
            { label: "Nie", value: "false" },
          ],
        },
        {
          type: FilterType.Text,
          label: "nr przelewu",
          placeholder: "Szukaj nr przelewu",
          name: "transferNumber",
        },
      ]}
    />
  ),
  routing: SalesInvoices,
};
